
















import Vue from 'vue';
import Component from 'vue-class-component';
import Axios from 'axios';
import { apiUrl, daysAgo } from '../../util';

@Component
export default class MonthlySummary extends Vue {

  private tableData: any;
  private ready = false;

  get headers() {
    return [
      { text: 'Site', value: 'site' },
      { text: 'Outlet', value: 'location' },
      { text: 'Cold Min', value: 'coldMin' },
      { text: 'Hot Max', value: 'hotMax' },
      { text: 'Cold Events', value: 'coldFlowCount' },
      { text: 'Cold Compliant', value: 'coldCompliantCount' },
      { text: 'Cold Compliance Rate', value: 'coldCompliance' },
      { text: 'Hot Events', value: 'hotFlowCount' },
      { text: 'Hot Compliant', value: 'hotCompliantCount' },
      { text: 'Hot Compliance Rate', value: 'hotCompliance' } ]
  }

  get items() {
    const seriesData = this.tableData.reduce((series: any, point: any) => {
      const siteName = point.site || 'Unknown';
      const locationName = point.location;
      const locationSeries = series.find((item: any) => item.site == siteName && item.location == locationName);

      const row = locationSeries || { site: siteName, location: locationName };

      if (point.type == 'Cold (standard)') {
        if (!row.coldMin) {
          row.coldMin = point.min_temp;
          row.coldMax = point.max_temp;
          row.coldCompliantCount = point.compliant_count;
          row.coldFlowCount = point.flow_count;
          row.coldCompliance = ((point.compliant_count) * 100 / (point.flow_count));
        } else {
          row.coldMin = (point.min_temp < row.coldMin) ? point.min_temp : row.coldMin;
          row.coldMax = (point.max_temp > row.coldMax) ? point.max_temp : row.coldMax;
          row.coldCompliantCount = row.coldCompliantCount + point.compliant_count;
          row.coldFlowCount = row.coldFlowCount + point.flow_count;
          row.coldCompliance = ((row.coldCompliantCount) * 100 / (row.coldFlowCount)).toFixed(0);
        }
      } else if (point.type == 'Hot (standard)') {
          if (!row.hotMin) {
          row.hotMin = point.min_temp;
          row.hotMax = point.max_temp;
          row.hotCompliantCount = point.compliant_count;
          row.hotFlowCount = point.flow_count;
          row.hotCompliance = ((point.compliant_count) * 100 / (point.flow_count));
        } else {
          row.hotMin = (point.min_temp < row.coldMin) ? point.min_temp : row.hotMin;
          row.hotMax = (point.max_temp > row.coldMax) ? point.max_temp : row.hotMax;
          row.hotCompliantCount = row.hotCompliantCount + point.compliant_count;
          row.hotFlowCount = row.hotFlowCount + point.flow_count;
          row.hotCompliance = ((row.hotCompliantCount) * 100 / (row.hotFlowCount)).toFixed(0);
        }
      }

      if (!locationSeries && (row.coldMin || row.hotMin)) {
        series.push(row);
      }

      return series;
    }, []);

    console.log(seriesData);
    return seriesData;
  }

  public async mounted() {
    const response = (await Axios.get(`${apiUrl()}/sensorData?since=${daysAgo(30)}`));
    this.tableData = response.data.data;
    this.ready = true;
  }

  private redIfCountZero(count: number | string) {
    return (Number(count) === 0) ? '#660000' : '#006600'
  }

  private redIfNotHundred(count: number | string) {
    return (Number(count) !== 100) ? '#660000' : '#006600'
  }
}
