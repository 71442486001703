export function generateHourlyTimeSeries(days: number): number[][] {
  const count = days * 24;
  let i = 0;
  const series = [];
  let x = new Date("1 Feb 2022").getTime();
  let lastValue = (Math.random() * 10) + 10;
  while (i < count) {
    lastValue = lastValue + ((Math.random() * 4) - (lastValue / 10))
    series.push([x, lastValue + ((Math.random() * 4) - (lastValue / 10))]);
    x += (86400000 / 24);
    i++;
  }
  return series;
}

export function noDP(val: number) {
  return val.toFixed(0);
}

export function apiUrl() {
  // yeah, I know, but I couldn't get env variables working :(
  if (window.location.host === 'legionella.daizy.io') {
    return 'https://legionella-api.daizy.io'
  } else {
    return 'http://localhost:8089'
  }
}

export function daysAgo(days: number) {
  return new Date(new Date().getTime() - (86400000 * days)).toISOString();
}
