



















import Vue from 'vue'
import Component from 'vue-class-component';
import NonCompliantEventsLast7Days from '../components/charts/NonCompliantEventsLast7Days.vue';
import TemperatureLastXDays from '../components/charts/TemperatureLastXDays.vue';
import TemperatureRangeLastXDays from '../components/charts/TemperatureRangeLastXDays.vue';
import MonthlySummary from '../components/tables/MonthlySummary.vue';

@Component({
  components: {
    NonCompliantEventsLast7Days,
    TemperatureLastXDays,
    TemperatureRangeLastXDays,
    MonthlySummary,
  }
})
export default class Dashboard extends Vue {

}
