







import Component from 'vue-class-component';
import Vue from 'vue';
import { apiUrl, daysAgo, noDP } from '../../util';
import Axios from 'axios';
import { Prop } from 'vue-property-decorator';

@Component
export default class NonCompliantEventsLast7Days extends Vue {

  @Prop({ default: 500 }) width!: number;
  @Prop({ default: 400 }) height!: number;

  public graphData: any;
  public ready = false;

  async mounted() {
    const response = (await Axios.get(`${apiUrl()}/sensorData?since=${daysAgo(7)}`));
    this.graphData = response.data.data;
    this.ready = true;
  }

  get options() {
    return {
      title: {
        text: 'Non Compliant Events - Last 7 Days'
      },
      chart: {
        stacked: true,
        foreColor: '#ccc',
        toolbar: {
          show: false
        },
      },
      theme: {
        monochrome: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        theme: 'dark'
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        labels: {
          formatter: noDP
        }
      },
      grid: {
        borderColor: "#535A6C",
        xaxis: {
          lines: {
            show: true
          }
        }
      }
    }
  }

  get series() {
    const allTimestamps = new Set<number>();
    const allLocations = new Set<string>();
    const seriesByTimeBucket = this.graphData.reduce((series: Record<string, any>, point: any) => {
      const timestamp = new Date(point.dt).getTime();
      const timeBucket = Math.round(timestamp / 86400000) * 86400000;
      allTimestamps.add(timeBucket);
      const siteName = point.site || point.location || 'Unknown';
      allLocations.add(siteName);
      let bucket = series[timeBucket];
      if (bucket && bucket[siteName]) {
        // console.log(`already a bucket for ${timeBucket} ${siteName}`)
        bucket[siteName] = bucket[siteName] + (point.flow_count - point.compliant_count);
      } else {
        bucket = { ...bucket, [siteName]: point.flow_count - point.compliant_count };
      }
      series[timeBucket] = bucket;
      return series;
    }, {});

    const seriesByLocation = Array.from(allTimestamps).reduce((series: any[], timestamp: any) => {
      const readings = seriesByTimeBucket[timestamp];
      for (const location of Array.from(allLocations)) {
        const locationSeries = series.find((s: any) => s.name == location);
        if (locationSeries) {
          locationSeries.data.push([Number(timestamp), readings[location] || 0]);
        } else {
          series.push({
            name: location,
            data: [[Number(timestamp), readings[location] || 0]]
          })
        }
      }
      return series;
    }, [])

    console.log(JSON.stringify(seriesByLocation));
    return seriesByLocation;
  }
}
