







import Component from 'vue-class-component';
import Vue from 'vue';
import { apiUrl, daysAgo, generateHourlyTimeSeries } from '../../util';
import { Prop, Watch } from 'vue-property-decorator';
import Axios from 'axios';

@Component
export default class TemperatureLastXDays extends Vue {

  @Prop() site!: string;
  @Prop() measure!: string;
  @Prop() type!: string;
  @Prop() days!: number;
  @Prop() threshold!: number;

  private ready = false;
  private graphData: any = {};

  @Watch('site')
  public async updateData() {
    const { data } = (await Axios.get(`${apiUrl()}/sensorData?since=${daysAgo(this.days)}&site=${this.site}`)).data;

    const newData = data.reduce((series: any, point: any) => {
      if (point.type !== this.type) return series;
      const locationSeries = series.find((s: any) => s.name === point.location);

      const dataPoint = [point.dt, point[this.measure]];

      if (locationSeries) {
        locationSeries.data.push(dataPoint)
      } else {
        series.push({ name: point.location, data: [dataPoint]})
      }

      return series;
    }, [])

    this.graphData = newData;
    this.ready = true;
  }

  get options(): any {
    return {
      title: {
        text: `${this.type} ${this.measure} - Last 7 Days`
      },
      chart: {
        foreColor: '#999',
        toolbar: {
          autoSelected: 'zoom'
        },
      },
      theme: {
        monochrome: {
          enabled: false
        }
      },
      stroke: {
        width: 1,
        curve: 'smooth'
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        theme: 'dark'
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
          labels: {
            formatter: function (val: number) {
              return val.toFixed(0);
            },
          },
          title: {
            text: 'Temperature (C)'
          },
      },
      annotations: {
        yaxis: [{
          y: this.threshold,
          borderColor: '#f00',
          stroke: {
            width: 2
          },
          label: {
            text: `Compliance threshold`
          }

        }]
      },
      grid: {
        borderColor: "#535A6C",
        xaxis: {
          lines: {
            show: true
          }
        }
      }
    }
  }
}
