var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":50,"group-by":"site"},scopedSlots:_vm._u([{key:"item.coldFlowCount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.redIfCountZero(item.coldFlowCount)}},[_vm._v(_vm._s(item.coldFlowCount))])]}},{key:"item.coldCompliance",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.redIfNotHundred(item.coldCompliance)}},[_vm._v(_vm._s(isNaN(item.coldCompliance) ? '-' : ((item.coldCompliance) + "%")))])]}},{key:"item.hotFlowCount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.redIfCountZero(item.hotFlowCount)}},[_vm._v(_vm._s(item.hotFlowCount))])]}},{key:"item.hotCompliance",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.redIfNotHundred(item.hotCompliance)}},[_vm._v(_vm._s(isNaN(item.hotCompliance) ? '-' : ((item.hotCompliance) + "%")))])]}}],null,false,2485357726)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }