







import Component from 'vue-class-component';
import Vue from 'vue';
import { apiUrl, daysAgo, generateHourlyTimeSeries, noDP } from '../../util';
import { Prop, Watch } from 'vue-property-decorator';
import Axios from 'axios';

@Component
export default class TemperatureRangeLastXDays extends Vue {

  @Prop() site!: string;
  @Prop() days!: number;
  @Prop() thresholdMax!: number;
  @Prop() thresholdMin!: number;
  @Prop({ default: 500 }) width!: number
  @Prop({ default: 400 }) height!: number

  private graphData: any;
  private ready = false;

  public async mounted() {
    const { data } = (await Axios.get(`${apiUrl()}/sensorData?since=${daysAgo(30)}`)).data;

    const seriesData = data.reduce((series: any, point: any) => {
      const site = point.site || point.location || 'Unknown';

      const siteSeries = series.find((s: any) => s.x == site);

      if (siteSeries) {
        const [min, max] = siteSeries.y;
        if (point.min_temp < min) {
          siteSeries.y[0] = point.min_temp;
        }

        if (point.max_temp > max) {
          siteSeries.y[1] = point.max_temp;
        }
      } else {
        series.push({ x: site, y: [point.min_temp, point.max_temp]});
      }
      return series;
    }, []);

    this.graphData = seriesData;
    this.ready = true;
  }

  get options(): any {
    const seriesData = this.series[0].data;
    return {
      title: {
        text: 'Max/Min Temperature - Last 30 Days'
      },
      chart: {
        foreColor: '#999',
        toolbar: {
          autoSelected: 'zoom'
        },
      },
      theme: {
      },
      stroke: {
        width: 2
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        theme: 'dark'
      },
      xaxis: {
      },
      yaxis: {
          labels: {
            formatter: noDP
          },
          title: {
            text: 'Temperature (C)'
          },
          min: -5,
          max: 100
      },
      annotations: {
        yaxis: [{
          y: this.thresholdMin,
          y2: this.thresholdMax,
          fillColor: '#cceecc',
          opacityFrom: 0.2,
          opacityTo: 0.2
        }]
      },
      grid: {
        borderColor: "#535A6C",
        xaxis: {
          lines: {
            show: true
          }
        }
      }
    }
  }

  get series(): any {
    // const series = [{
    //     name: 'thing',
    //     data: [{ x: 'The Kings Head, Lymington', y: [8, 32], fillColor: '', borderColor: ''},
    //     { x: 'The Chequers Inn, Pennington', y: [9, 43]},
    //     { x: 'The Beach House, Milford-on-sea', y: [17, 24]},
    //     { x: 'The Crown, Everton', y: [13, 44]}]
    //   }]

    for (const point of this.graphData) {
      point.fillColor = point.borderColor = '#aa6688';
      for (const value of point.y) {
        if (value < this.thresholdMin || value > this.thresholdMax) {
          point.fillColor = '#66aa88'
        }
      }
    }

    return [{
      name: '',
      data: this.graphData
    }];
  }
}
