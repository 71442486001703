



























import Vue from 'vue'
import Component from 'vue-class-component';
import { apiUrl } from '../util';
import Axios from 'axios';
import TemperatureLastXDays from '../components/charts/TemperatureLastXDays.vue';

@Component({
  components: {
    TemperatureLastXDays,
  }
})
export default class SiteExplorer extends Vue {
  private siteLocations!: string[];
  private ready = false;

  private selectedSite = '';

  public async mounted() {
    const positions = (await Axios.get(`${apiUrl()}/positions`)).data;
    this.siteLocations = positions.reduce((sites: any, position: any) => {
      const siteName = position.site || position.location;
      if (siteName == 'Test Bench') {
        return sites;
      }
      sites[siteName] = [...(sites[siteName] || []), position.location]
      return sites;
    }, {})

    this.ready = true;
  }

  get sites() {
    return Object.keys(this.siteLocations).map((s: string) => ({
      name: s, id: s
    }))
  }
}
